<template>
  <div>
    <fieldset>
      <legend>General</legend>
      <div class="row">
        <div class="col-md-4">
          <FormGroupSelect
              :ref="'field_customer_id'"
              v-model="value.customer_id"
              :label="modelConfig.fields.customer_id.label"
              fieldname="customer_id"
              :titleProperty="modelConfig.fields.customer_id.resolve.titleProperty"
              :optionsDataService="modelConfig.fields.customer_id.resolve.optionsDataService"
              :validation="validation"
              :cfg="modelConfig.fields.customer_id"
              @formSubmitRequest="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-4">
          <FormGroupSelect
              :ref="'field_contract_position_id'"
              v-model="value.contract_position_id"
              fieldname="contract_position_id"
              :label="modelConfig.fields.contract_position_id.label"
              :titleProperty="modelConfig.fields.contract_position_id.resolve.titleProperty"
              :optionsDataService="modelConfig.fields.contract_position_id.resolve.optionsDataService"
              :validation="validation"
              :cfg="modelConfig.fields.contract_position_id"
              :fixedFilters="getContractPositionFixedFilters"
              @formSubmitRequest="$emit('formSubmitRequest')"
              @currentModelRecord="onEmitContractPosition"
          />
        </div>
        <div class="col-md-4">
          <FormGroupInput
              ref="field_date"
              v-model="value.date"
              fieldname="date"
              :validation="validation"
              :cfg="modelConfig.fields.date"
              @formSubmitRequest="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-6">
          <FormGroupSelect
              :ref="'field_user_id'"
              v-model="value.user_id"
              fieldname="user_id"
              :label="modelConfig.fields.user_id.label"
              :titleProperty="modelConfig.fields.user_id.resolve.titleProperty"
              :optionsDataService="modelConfig.fields.user_id.resolve.optionsDataService"
              :validation="validation"
              :cfg="modelConfig.fields.user_id"
              @formSubmitRequest="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-6">
          <FormGroupSelect
              :ref="'field_invoice_id'"
              v-model="value.invoice_id"
              fieldname="invoice_id"
              :label="modelConfig.fields.invoice_id.label"
              :titleProperty="modelConfig.fields.invoice_id.resolve.titleProperty"
              :optionsDataService="modelConfig.fields.invoice_id.resolve.optionsDataService"
              :validation="validation"
              :cfg="modelConfig.fields.invoice_id"
              @formSubmitRequest="$emit('formSubmitRequest')"
          />
        </div>
      </div>
    </fieldset>
    <fieldset v-if="type == 'timesheet'">
      <legend>Timesheet</legend>
      <div class="row">
        <div class="col-md-4">
          <FormGroupInput
              ref="field_reference"
              v-model="value.reference"
              fieldname="reference"
              :validation="validation"
              :cfg="modelConfig.fields.reference"
              @formSubmitRequest="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-4">
          <FormGroupInput
              ref="field_description"
              v-model="value.description"
              fieldname="description"
              :validation="validation"
              :cfg="modelConfig.fields.description"
              @formSubmitRequest="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-4">
          <FormGroupInput
              ref="field_amount"
              v-model="value.amount"
              fieldname="amount"
              :validation="validation"
              :cfg="modelConfig.fields.amount"
              @formSubmitRequest="$emit('formSubmitRequest')"
          />
        </div>
      </div>
    </fieldset>
    <fieldset v-if="type == 'product'">
      <legend>Product</legend>
      <div class="row">
        <div class="col-md-4">
          <FormGroupInput
              ref="field_reference"
              v-model="value.reference"
              fieldname="reference"
              :validation="validation"
              :cfg="modelConfig.fields.reference"
              @formSubmitRequest="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-4">
          <FormGroupInput
              ref="field_description"
              v-model="value.description"
              fieldname="description"
              :validation="validation"
              :cfg="modelConfig.fields.description"
              @formSubmitRequest="$emit('formSubmitRequest')"
          />
        </div>
        <div class="col-md-4">
          <FormGroupInput
              ref="field_amount"
              v-model="value.amount"
              fieldname="amount"
              :validation="validation"
              :cfg="productAmountFieldConfig()"
              @formSubmitRequest="$emit('formSubmitRequest')"
          />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>

import FormGroupInput from '@/components/Form/FormGroup/Input.vue';
import FormGroupSelect from '@/components/Form/FormGroup/Select.vue';
import _ from 'lodash';

export default {
  props: {
    modelConfig: null,
    dataService: null,
    modelValue: null,
    validation: null,
  },
  emits: ['update:modelValue', 'formSubmitRequest'],
  data() {
    const that = this;
    return {
      type: null,
      productAmountFieldConfig() {
        let cfg = _.cloneDeep(that.modelConfig.fields.amount);
        cfg.label = "Price";
        cfg.edit.min = '0.01';
        cfg.edit.step = '0.01';
        return cfg;
      }
    }
  },
  methods: {
    onEmitContractPosition(cp) {
      this.type = cp.type;
    },
    getContractPositionFixedFilters() {
      if (this.currentEntity) {
        return { 'contract.customer_id': this.currentEntity.customer_id }
      }
    }

  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  name: "BillableFormFields",
  components: {
    FormGroupInput,
    FormGroupSelect,
  },
}
</script>
