<script>
import ModelForm from '@/components/Form/ModelForm.vue';
import FormFields from '@/components/BillableForm/Fields.vue';
import BillableDataService from "@/services/BillableDataService";
import store from '@/store';
import ActivityDataService from "@/services/ActivityDataService";

export default {
  name: 'BillableForm',
  extends: ModelForm,
  props: {
    apiSlug: { default: 'billables' },
    vueSlug: { default: 'billable' }
  },
  components: {
    FormFields
  },
  data() {
    return {
      dataService: BillableDataService,
      modelConfig: BillableDataService.getEditorConfig(),
      currentEntity: BillableDataService.getEntityDefinition(),
    }
  },
  watch: {
    currentEntity: function (v) {
      if (v.contract_position) {
        this.$refs.formFields.type = v.contract_position.type;
      }

      // disable all elements if billable is invoiced
      if (v.invoice_id) {
        // disable fields
        this.disableFormFields();
        // disable buttons
        this.disableFormButtons(['back_to_list', 'copy']);
      }
    }
  },
  mounted() {
    this.setInitialEntityValues();
    this.initFieldDependencyHandler();
  },
  methods: {
    setInitialEntityValues() {
      const that = this;
      // new record
      if (this.$route.name.startsWith('new-')) {

        // new record with given customer_id if given
        const cid = this.$route.params.customer_id;
        if (cid) {
          this.currentEntity.customer_id = cid;
        }

        // new record with current date as date
        const d = new Date();
        this.currentEntity.date = this.getDateString(d);

        // new record with current user as user
        this.currentEntity.user_id = store.state.auth.token.data.user.id;

        // new record with reference given
        const reference = this.$route.params.reference;
        if (reference) {
          this.currentEntity.reference = reference;
        }

        // new record with date given
        const date = this.$route.params.date;
        if (reference) {
          this.currentEntity.date = date;
        }

        // new record with given activity_id if given - update activity record on create
        const aid = this.$route.params.activity_id;
        if (aid) {
          this.onAfterCreateHandlers.push(function(billable) {
            ActivityDataService.get(aid).then(response => {
              let record = response.data;
              record.billable_id = billable.id;
              ActivityDataService.update(aid, record).then(() => {
                that.$store.state.messages.push({title: "Success", message: "The activity has been updated successfully!", type: "success"});
              }).catch(e => { that.handleRequestError(e) });
            }).catch(e => { that.handleRequestError(e) });
          });
        }
      }
    },
    initFieldDependencyHandler() {
      // do not handle if already invoiced
      if (this.currentEntity.invoice_id) {
        return;
      }

      // handle customer_id contract_position_id dependency
      const customerSelect = this.$refs.formFields.$refs.field_customer_id.$el;
      const contractPositionComponent = this.$refs.formFields.$refs.field_contract_position_id;

      // set filter to zero, so no records will be found on new record or if no id is given by route
      if (this.$route.name.startsWith('new-') && ! this.currentEntity.customer_id) {
        contractPositionComponent.disabled = true
      } else {
        contractPositionComponent.disabled = false
      }

      // on changing the customer_id, set fixed filters on contract_position_id select
      customerSelect.addEventListener('change', function(e) {
        const val = e.target.value == "" ? 0 : e.target.value;
        contractPositionComponent.fixedFilters = {
          'contract.customer_id': val
        }
        if (! val) {
          contractPositionComponent.value = '';
          contractPositionComponent.disabled = true
        } else {
          contractPositionComponent.disabled = false
        }
      }, false);

      // initial set fixed filters on contract_position_id select if given in route
      // this.currentEntity.customer_id is then set in setInitialEntityValues
      if (this.$route.name.startsWith('new-') && this.currentEntity.customer_id) {
        contractPositionComponent.fixedFilters = {
          'contract.customer_id': this.currentEntity.customer_id
        }
      }

      const that = this;

      // handle contract_position.type  - fieldset dependency
      const cpSelect = this.$refs.formFields.$refs.field_contract_position_id.$el;
      const cpComponent = this.$refs.formFields.$refs.field_contract_position_id;
      cpSelect.addEventListener('change', function(e) {
        let contractPosition = cpComponent.getRecord(e.target.value);
        if (contractPosition) {
          that.$refs.formFields.type = contractPosition.type;
        }
      }, false);
    }
  },
  setup() {
    return ModelForm.setup();
  },
  validations() {
    return {
      currentEntity: this.dataService.getValidationRules()
    }
  }
}
</script>
