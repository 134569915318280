<template>
  <div>
    <BillableForm ref="form" @mainRecordLoaded="onMainRecordLoaded" :key="$route.path" :buttons="additionalFormButtons">
      <template v-if="mainRecord && mainRecord.invoice_id" #before_buttons>
        <div class="alert alert-warning" role="alert">
          The record has been invoiced already, so no changes are possible anymore!
        </div>
      </template>
    </BillableForm>
  </div>
</template>

<script>
import BillableForm from "@/components/BillableForm";

export default {
  name: "BillableView",
  components: {BillableForm},
  data() {
    const that = this;
    return {
      mainRecord: null,
      additionalFormButtons: {
        copy: {
          text: "Copy",
          class: "warning",
          icon: "copy",
          click: function () {
            that.mainRecord["created_at"] = null
            that.mainRecord["id"] = null
            that.mainRecord["invoice_id"] = null
            that.mainRecord["updated_at"] = null
            that.isLoading = true
            that.$refs.form.dataService.create(that.mainRecord).then(response => {
              that.isLoading = false
              that.$store.state.messages.push({title:"Success", type:"success", message: 'The record was copied!'});
              that.$router.push('/billable/' + response.data.id)
            })
          }
        }
      }
    }
  },
  methods: {
    onMainRecordLoaded(record) {
      this.mainRecord = record;
    }
  },
  watch: {
    '$route.path': function() {
      this.mainRecord = null
    }
  }
}
</script>
